/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";

import EditIcon from "@locaisolutions/icons/dist/icons24px/Edit24Px";
import CloseCircleIcon from "@locaisolutions/icons/dist/icons24px/Close24Px";
import AttentionIcon from "@locaisolutions/icons/dist/icons24px/Info24Px";

import ItemInput from "../input/ItemInput";
import { SelectedConfigurationItemType } from "./ConfigurationItems";
import SelectedConfigItem from "./SelectedConfigItem";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import {
  generateUUID,
  getApproximateEnvTagsContainerWidth
} from "../../lib/helpers";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import EnvironmentTagButton, {
  EnvironmentTagsContainer
} from "./EnvironmentTagButton";
import CopyToClipboardButton from "../input/CopyBtn";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { selectArePendingChangesAvailable } from "../../redux/selectors/siteSelectors";

export const ItemInputContainer = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "center",
  alignSelf: "baseline",
  gap: "10px",
  flexWrap: "wrap"
}));

export const ItemValuesContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: 12
}));

const MainContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isPendingChangesListDrawerDisplayed: boolean };
}>(({ extraProps, theme }) => ({
  display: "grid",
  gridTemplateColumns: "minmax(auto, 250px) 1fr",
  gap: theme.spacing(1),
  margin: "0px 10px",
  justifyItems: "stretch",
  ...(extraProps.isPendingChangesListDrawerDisplayed && {
    "@media (max-width: 1650px)": {
      gridTemplateColumns: "1fr",
      margin: "0px",
      justifyItems: "center",
      gap: theme.spacing(3)
    }
  }),
  "@media (max-width: 1400px)": {
    gridTemplateColumns: "1fr",
    margin: "0px",
    justifyItems: "center",
    gap: theme.spacing(3)
  }
}));

const ItemValueNameContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isPendingChangesListDrawerDisplayed: boolean };
}>(({ extraProps, theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 45px",
  marginTop: "8px",
  gap: theme.spacing(1),
  width: "250px",
  alignItems: "flex-start",
  justifyContent: "start",
  ...(extraProps.isPendingChangesListDrawerDisplayed && {
    "@media (max-width: 1650px)": {
      width: "100%",
      alignItems: "center",
      gridTemplateColumns: "minmax(auto, 200px) 45px"
    }
  }),
  "@media (max-width: 1400px)": {
    width: "100%",
    alignItems: "center",
    gridTemplateColumns: "minmax(auto, 200px) 45px"
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr"
  }
}));

export const DescriptionTooltip = (props: {
  additionalStyling?: unknown;
  description: string;
}) => {
  return (
    <Tooltip
      title={props.description}
      aria-label="configuration-item-description"
      placement="top"
    >
      <span
        style={{
          position: "relative",
          ...(props?.additionalStyling ? props.additionalStyling : {})
        }}
      >
        <AttentionIcon height={20} width={20} />
      </span>
    </Tooltip>
  );
};
export const EditCloseButton = (props: {
  onClickBtn: () => void;
  isConfigItemSelected: boolean;
}) => {
  const { onClickBtn, isConfigItemSelected } = props;
  return (
    <IconButton
      sx={
        !isConfigItemSelected
          ? {
              height: 42,
              width: 42,
              borderRadius: "6px",
              backgroundColor: "secondary.dark",
              ":hover": { backgroundColor: "secondary.light" },
              "& .MuiTouchRipple-child": { borderRadius: "inherit" }
            }
          : {
              padding: 0,
              mt: "3px",
              mr: "3px",
              height: 20,
              width: 20
            }
      }
      aria-label="edit-input-btn"
      disableRipple
      onClick={onClickBtn}
      data-testid={
        !isConfigItemSelected ? "edit-input-btn" : "close-edit-mode-btn"
      }
    >
      {!isConfigItemSelected ? (
        <EditIcon height={30} width={30} />
      ) : (
        <CloseCircleIcon />
      )}
    </IconButton>
  );
};

type ConfigurationItemProps = {
  selectedSectionItem: ConfigurationItemType;
  selectedConfigurationItem: SelectedConfigurationItemType;
  saveSelectedConfigurationItemChanges: (
    pendingChange: PendingChangeType
  ) => void;
  clearSelectedConfigItem: () => void;
  updateSelectedConfigurationItem: (
    configurationItem: SelectedConfigurationItemType
  ) => void;
  isPartOfPendingChangesList?: boolean;
  isInNewSiteCreatingMode?: boolean;
};

function ConfigurationItem(props: ConfigurationItemProps) {
  const {
    selectedSectionItem,
    selectedConfigurationItem,
    updateSelectedConfigurationItem,
    clearSelectedConfigItem,
    isPartOfPendingChangesList,
    isInNewSiteCreatingMode
  } = props;
  const {
    name,
    description,
    dataType,
    configurationItemId,
    configurationValues,
    validationRules,
    configurationId
  } = selectedSectionItem;
  const isPendingChangesListDrawerDisplayed = useSelector(
    selectArePendingChangesAvailable
  );
  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(validationRules, environmentsDetailsById);

  const isConfigItemSelected =
    selectedConfigurationItem.configurationItemId === configurationItemId;
  const isJSONType = ["json", "jsonb"].includes(dataType);

  const onEditBtnClick = (item: ConfigurationItemType) => {
    updateSelectedConfigurationItem({
      configurationName: name,
      configurationItemId,
      originalValues: configurationValues,
      currentSelectedConfigValue: item
    });
  };

  useEffect(() => {
    clearSelectedConfigItem();
  }, []);

  return (
    <MainContainer
      extraProps={{
        isPendingChangesListDrawerDisplayed
      }}
      data-testid="cofig-item-container"
      key={`config-item-${name}-${configurationId}`}
    >
      <ItemValueNameContainer
        extraProps={{
          isPendingChangesListDrawerDisplayed
        }}
      >
        <Stack
          gridColumn="1/2"
          flexDirection="row"
          justifyItems="space-between"
          gap={1}
        >
          <Typography
            sx={{
              fontSize: "0.8rem"
            }}
          >
            {name}
          </Typography>
          {!!description.length && (
            <DescriptionTooltip description={description} />
          )}
        </Stack>
        <Box
          width={42}
          sx={{
            gridColumn: "2/3"
          }}
        >
          {!isJSONType &&
          !isPartOfPendingChangesList &&
          !isConfigItemSelected ? (
            <EditCloseButton
              isConfigItemSelected={false}
              onClickBtn={() => onEditBtnClick(selectedSectionItem)}
            />
          ) : (
            <></>
          )}
        </Box>
      </ItemValueNameContainer>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "repeat(2, auto)",
          gap: 2
        }}
      >
        <Stack flexDirection="column" gap={2}>
          {configurationValues.map((item: ConfigurationValue, index) => {
            const { environmentIds, configurationValue } = item;
            const currentValidationRules =
              validationRulesPerEnvironment[environmentIds[0]];

            const validationRule =
              currentValidationRules?.validationRules || null;
            const isRequired = currentValidationRules?.isRequired || false;

            const shouldRenderCopyBtn =
              isConfigItemSelected &&
              ["text", "url", "json", "jsonb"].includes(dataType) &&
              typeof configurationValue === "string";
            const approximateEnvTagcontainerWidth =
              getApproximateEnvTagsContainerWidth(environmentIds.length);
            return (
              <Stack
                flexDirection="row"
                gap={1}
                key={`environment-values-item-${configurationItemId}-${index}`}
              >
                <Box
                  sx={{
                    margin: "8px 0px 0px 5px",
                    height: 30,
                    width: 30
                  }}
                >
                  {shouldRenderCopyBtn ? (
                    <CopyToClipboardButton value={configurationValue} />
                  ) : (
                    <></>
                  )}
                </Box>

                <Stack
                  flexDirection="row"
                  gap={1}
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  <ItemInput
                    configurationValue={configurationValue}
                    dataType={dataType}
                    required={isRequired}
                    readOnly
                    validationRules={validationRule}
                    isPartOfPendingChangesList={isPartOfPendingChangesList}
                    isInNewSiteCreatingMode={isInNewSiteCreatingMode}
                  />
                  <EnvironmentTagsContainer
                    minWidth={approximateEnvTagcontainerWidth}
                    sx={{
                      justifyContent: "center"
                    }}
                  >
                    {allAvailableEnvironmentNames.map((envName) => {
                      if (
                        environmentIds.find(
                          (envId: string) =>
                            envName ===
                            environmentsDetailsById?.[envId]?.environmentName
                        )
                      )
                        return (
                          <EnvironmentTagButton
                            key={`env-${envName || generateUUID()}`}
                            envName={envName}
                            selected
                            readonly
                          />
                        );
                      return <></>;
                    })}
                  </EnvironmentTagsContainer>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Collapse
          in={isConfigItemSelected}
          sx={{
            gridRow: "2/3",
            ml: 5,
            "@media (max-width: 1400px)": {
              ml: isPartOfPendingChangesList ? 0 : 1
            }
          }}
        >
          {isConfigItemSelected && (
            <SelectedConfigItem
              currentConfigurationItem={selectedSectionItem}
              clearSelectedConfigItem={props.clearSelectedConfigItem}
              selectedConfigurationItem={selectedConfigurationItem}
              saveSelectedConfigurationItemChanges={
                props.saveSelectedConfigurationItemChanges
              }
            />
          )}
        </Collapse>
      </Box>
    </MainContainer>
  );
}

export default ConfigurationItem;
