import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import SiteConfigurations from "./SiteConfigurations";
import ConfigurationItems from "./ConfigurationItems";
import {
  getPendingConfigurationItemsList,
  getSelectedSiteSection,
  getSitetoDuplicateOrcreateInfo,
  selectArePendingChangesAvailable
} from "../../redux/selectors/siteSelectors";
import Header from "./Header";
import PendingChangesList from "./PendingChangesList";
import { clearSiteToDuplicateOrCreateInstance } from "./siteSlice";
import { useAppDispatch } from "../../store";
import DeploymentEventsStatusDrawer from "../deploymentsStatus/DeploymentEventsStatusDrawer";
import { useGetDeploymentsStatusQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  height: `calc(100% - 110px)`,
  gridTemplateRows: "60px 1fr",
  padding: 10,
  borderRadius: "6px",
  background: theme.palette.secondary.dark,
  margin: 15
}));

const SecondaryContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  border: `1px 0px 0px 0px`,
  borderColor: theme.palette.divider,
  borderRadius: "6px",
  background: theme.palette.background.paper,
  height: "100%",
  overflow: "hidden"
}));

function MainSiteContainer() {
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const isSiteSectionSelected = !!selectedSiteSection.id;
  const shouldRenderPendingChanges = useSelector(
    selectArePendingChangesAvailable
  );

  const [isDeploymentEventsDrawerOpen, updateDeploymentsDrawerStatus] =
    useState(false);
  const siteToDuplicateOrCreate = useSelector(getSitetoDuplicateOrcreateInfo);
  const isInNewSiteCreatingMode =
    !!siteToDuplicateOrCreate?.generalSiteInformation?.siteId;

  const selectedClientDrawerData = useSelector(getSelectedDrawersData).site;
  useGetDeploymentsStatusQuery(
    {
      siteId: selectedClientDrawerData?.id || ""
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedClientDrawerData?.id || isInNewSiteCreatingMode
    }
  );

  useEffect(() => {
    return () => {
      dispatch(clearSiteToDuplicateOrCreateInstance());
    };
  }, []);
  const deploymentStatusDrawerWidth = !isDeploymentEventsDrawerOpen
    ? "51px"
    : "300px";

  return (
    <MainContainer id="main-site-container">
      <Header shouldRenderPendingChanges={shouldRenderPendingChanges} />
      <SecondaryContainer
        sx={{
          gridTemplateColumns: shouldRenderPendingChanges
            ? `minmax(200px, 250px) minmax(450px, 1fr) minmax(270px, 300px) minmax(${isDeploymentEventsDrawerOpen ? "270px" : "51px"} , ${deploymentStatusDrawerWidth})`
            : `minmax(200px, 250px) minmax(450px, 1fr) ${deploymentStatusDrawerWidth}`
        }}
      >
        <SiteConfigurations siteToDuplicateOrCreate={siteToDuplicateOrCreate} />
        {isSiteSectionSelected && (
          <ConfigurationItems
            siteToDuplicateOrCreate={siteToDuplicateOrCreate}
          />
        )}
        {shouldRenderPendingChanges && (
          <PendingChangesList
            siteToDuplicateOrCreate={siteToDuplicateOrCreate}
          />
        )}
        {!isInNewSiteCreatingMode && (
          <DeploymentEventsStatusDrawer
            isDeploymentEventsDrawerOpen={isDeploymentEventsDrawerOpen}
            updateDeploymentsDrawerStatus={(isDrawerOpen) => {
              updateDeploymentsDrawerStatus(isDrawerOpen);
            }}
          />
        )}
      </SecondaryContainer>
    </MainContainer>
  );
}

export default MainSiteContainer;
