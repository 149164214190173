import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { useGetSiteConfigurationsListQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

import SiteConfiguration, {
  SectionGroupingMenuTitle
} from "./SiteConfiguration";
import Loading from "../Loading";
import { SiteToDuplicateOrCreateType } from "./siteSlice";
import ErrorPanel from "../ErrorPanel";

const MainContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  gap: "8px",
  padding: "20px 10px",
  borderRadius: "6px",
  background: theme.palette.background.default,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "1px solid rgba(0,0,0,0.2)",
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}));

type SiteConfigurationsProps = {
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType | null;
};
function SiteConfigurations(props: SiteConfigurationsProps) {
  const { siteToDuplicateOrCreate } = props;
  const {
    siteConfigurationsByMenuType,
    generalSiteInformation,
    isSiteCreationPending
  } = siteToDuplicateOrCreate || {};
  const { formattedConfigurationsByMainGroupings: newSiteMenuGroupings } =
    siteConfigurationsByMenuType || {};
  const isInNewSiteCreatingMode = !!generalSiteInformation?.siteId;
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteId = selectedDrawersData.site?.id;

  const { data, isFetching, isError, refetch } =
    useGetSiteConfigurationsListQuery(
      {
        siteId: siteId || ""
      },
      {
        skip: !siteId || isInNewSiteCreatingMode,
        refetchOnMountOrArgChange: true
      }
    );
  const menuItemsData = isInNewSiteCreatingMode
    ? siteConfigurationsByMenuType
    : (data as unknown as SiteConfigurationResponseType);
  const mainMenuSections =
    isInNewSiteCreatingMode && newSiteMenuGroupings
      ? Object.keys(newSiteMenuGroupings)
      : menuItemsData
        ? Object.keys(menuItemsData.formattedConfigurationsByMainGroupings)
        : [];
  const shouldRenderLoadingUI = isSiteCreationPending || isFetching;

  return (
    <MainContainer data-testid="site-configurations-menu">
      {mainMenuSections.map((configurationTypeId: string, index: number) => {
        const sectionItem =
          menuItemsData.formattedConfigurationsByMainGroupings[
            configurationTypeId
          ];
        const sectionConfigurationItems: SiteConfigurationMenuItem[] =
          sectionItem.configurations;

        const sectionName =
          menuItemsData.allConfigurationsMenuTypes[configurationTypeId]
            .configurationTypeName || "";

        return (
          <Stack
            flexDirection="column"
            gap={0.5}
            key={`main-site-section-${configurationTypeId}`}
            data-test-id={`main-site-section-${configurationTypeId}`}
          >
            <SectionGroupingMenuTitle data-testid="section-title">
              {sectionName}
            </SectionGroupingMenuTitle>
            {sectionConfigurationItems.map((section) => {
              return (
                <SiteConfiguration
                  key={`site-section-${section.configurationId}`}
                  sectionData={section}
                  allConfigurationsMenuTypes={
                    menuItemsData.allConfigurationsMenuTypes
                  }
                  isInNewSiteCreatingMode={isInNewSiteCreatingMode}
                />
              );
            })}
            {index !== mainMenuSections.length - 1 && (
              <Divider sx={{ borderBottomWidth: 2 }} />
            )}
          </Stack>
        );
      })}
      {!mainMenuSections.length && !shouldRenderLoadingUI && !isError && (
        <Box
          sx={{
            padding: "0.5rem",
            maxWidth: 200
          }}
        >
          <Typography fontSize={"0.875rem"}>
            No configuration groupings found.
          </Typography>
        </Box>
      )}
      {shouldRenderLoadingUI && <Loading numberOfRows={15} width={220} />}
      {isError && (
        <ErrorPanel
          errorMessage={`Failed to retrieve ${selectedDrawersData.site?.name} site sections data.`}
          size="small"
          sx={{
            textAlign: "center",
            maxHeight: "200px"
          }}
          onRetryCb={async () => {
            await refetch();
          }}
        />
      )}
    </MainContainer>
  );
}

export default SiteConfigurations;
