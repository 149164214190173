import { v4 } from "uuid";
import { SelectedDrawersDataType } from "../components/drawer/mainCustomDrawerSlice";

export const getDrawerNameToDisplayByType = (
  drawerTypeName: DrawerType
): string => {
  switch (drawerTypeName) {
    case "partner":
      return "Partner";
    case "client":
      return "Client";
    case "site":
      return "Site";
  }
};

export const getNumbersOfDrawersWithContentSelected = (
  selectedDrawersData: SelectedDrawersDataType
): number => Object.values(selectedDrawersData).filter((el) => !!el).length;

export const generateUUID = (): string => v4();

/**
 * Checks if a given string is a valid URL.
 *
 * This function verifies whether the input string matches a general URL format,
 * supporting different protocols (`http`, `https`, `ftp`, `file`, `ws`, `wss`),
 * domains, IP addresses (both IPv4 & IPv6), ports, paths, query strings, and fragments.
 *
 * @param {string} str - The string to be validated as a URL.
 * @returns {boolean} `true` if the string is a valid URL, otherwise `false`.
 */
export const isURL = (str: string): boolean => {
  const uriRegex =
    /^(?:(?:https?|ftp|file|ws|wss):\/\/)?(?:([\w-]+\.)*[a-zA-Z0-9-]+|\d{1,3}(?:\.\d{1,3}){3}|\[[a-fA-F0-9:]+\])(?::\d{1,5})?(?:\/[^\s]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?$/;

  const regex = new RegExp(uriRegex);
  return !!str.match(regex);
};

const convertToSnakeCase = (key: string) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const convertObjectToSnakeCase = (item: unknown) => {
  if (Array.isArray(item)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return item.map((el: unknown) => convertObjectToSnakeCase(el));
  }
  if (typeof item === "function" || !(item instanceof Object)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => {
      return [convertToSnakeCase(key), convertObjectToSnakeCase(value)];
    })
  );
};

/**
 * Calculates the approximate width required for a container holding environment tags.
 *
 * This function estimates the width based on the number of required environment tags
 * and their fixed width with padding. It also accounts for the gap between rows of tags.
 *
 * @param {number} numberOfRequiredEnvironments - The number of environment tags to display.
 * @returns {number} The approximate width of the container in pixels.
 */
export const getApproximateEnvTagsContainerWidth = (
  numberOfRequiredEnvironments: number
): number => {
  if (!numberOfRequiredEnvironments) return 0;
  const maxTagWidthWithPadding = 70;
  const rowGap = 8;
  return (
    numberOfRequiredEnvironments * maxTagWidthWithPadding +
    (numberOfRequiredEnvironments - 1 * rowGap)
  );
};
